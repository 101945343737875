const questions = [
  {
    id: 1,
    position: "Frontend",
    question: "What is closure in JavaScript?",
    date: "2024-02-28",
    company: "Google"
  },
  {
    id: 2,
    position: "Frontend",
    question: "What is React Hooks?",
    date: "2024-02-25",
    company: "Facebook"
  },
  {
    id: 3,
    position: "Cybersecurity",
    question: "What is SQL injection?",
    date: "2024-02-26",
    company: "Microsoft"
  },
  {
    id: 4,
    position: "Cybersecurity",
    question: "What is XSS attack?",
    date: "2024-02-24",
    company: "Amazon"
  },
  {
    id: 5,
    position: "Automation Test Engineer",
    question: "What is Test Plan?",
    date: "2024-03-01",
    company: "IBM"
  },
  {
    id: 6,
    position: "Automation Test Engineer",
    question: "What is Regression Testing?",
    date: "2024-02-27",
    company: "Oracle"
  },
  {
    id: 7,
    position: "Frontend",
    question: "What is JSX in React?",
    date: "2024-03-05",
    company: "Netflix"
  },
  {
    id: 8,
    position: "Frontend",
    question: "What is the Virtual DOM?",
    date: "2024-03-08",
    company: "Twitter"
  },
  {
    id: 27,
    position: "Frontend",
    question: "What are controlled components in React?",
    date: "2024-03-10",
    company: "LinkedIn"
  },
  {
    id: 28,
    position: "Cybersecurity",
    question: "What is Cross-Site Scripting (XSS)?",
    date: "2024-03-12",
    company: "Apple"
  },
  {
    id: 29,
    position: "Cybersecurity",
    question: "What is Network Sniffing?",
    date: "2024-03-15",
    company: "Cisco"
  },
  {
    id: 47,
    position: "Cybersecurity",
    question: "What is Denial of Service (DoS) attack?",
    date: "2024-03-18",
    company: "Cloudflare"
  },
  {
    id: 48,
    position: "Automation Test Engineer",
    question: "What is Black Box Testing?",
    date: "2024-03-20",
    company: "HP"
  },
  {
    id: 49,
    position: "Automation Test Engineer",
    question: "What is White Box Testing?",
    date: "2024-03-22",
    company: "Dell"
  },
  {
    id: 67,
    position: "Automation Test Engineer",
    question: "What is the difference between Verification and Validation?",
    date: "2024-03-25",
    company: "Accenture"
  }
];

export default questions;
