import React, { useState } from 'react'
import { prices } from '../data' // Assuming you have a data source for prices

const Service = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null)

  return (
    <section id='prices' className='w-full md:h-screen bg-[#00467d] text-gray-300'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>
            Services
          </p>
          <p className='py-3'>Flex Payment</p>
        </div>
        <div className='w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 text-center'>
          {prices.map((item, index) => {
            const { plan, duration, features, isPopular, description, price } = item
            const isHovered = hoveredIndex === index

            return (
              <div
                className={`shadow-md hover:shadow-lg hover:bg-[#040c16] hover:scale-105 transition-all duration-300 ${
                  isPopular ? 'border-2 border-green-500' : 'border border-gray-400'
                }`}
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {isPopular && (
                  <p className='bg-green-500 text-white py-1 px-2 rounded-tl-md rounded-tr-md absolute top-0 left-0'>
                    POPULAR
                  </p>
                )}
                <h4 className='text-2xl mb-2 font-primary font-semibold text-yellow-500'>{plan}</h4>
                <p className='text-lg mb-2 font-primary font-semibold'>{duration}</p>
                <p className='text-2xl mb-2 font-primary font-semibold text-white-500'>{price}</p>
                <ul className='text-left mx-auto'>
                  {features.map((feature, featureIndex) => (
                    <li key={featureIndex} className='mb-2 flex items-center'>
                      <svg
                        className='w-4 h-4 text-green-500 mr-2'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M5 13l4 4L19 7'
                        />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
                <button className='text-white px-4 py-2 rounded mt-4 mb-2 bg-blue-500 font-bold    hover:bg-purple-600 hover:border-pink-600'>
                  Learn more <hr />
                  {isHovered && <span className='tooltiptext'>{description}</span>}
                </button>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Service
