// import social icons
// import FacebookIcon from '../src/assets/social/facebook.png';
// import TwitterIcon from '../src/assets/social/twitter.png';
// import InstagramIcon from '../src/assets/social/instagram.png';
// import YoutubeIcon from '../src/assets/social/youtube.png';
// import PinterestIcon from '../src/assets/social/pinterest.png';

// import profile images
// import ProfilePhoto1 from '../src/assets/img/profile1.png';
// import ProfilePhoto2 from '../src/assets/img/profile2.png';
// import ProfilePhoto3 from '../src/assets/img/profile3.png';

// // import about icons
// import Soft from '../src/assets/soft.png';
// import Brand from '../src/assets/brand.png';
// import Mock from '../src/assets/mock.png';
// import Feedback from '../src/assets/feedback.png';
// import Skill5 from '../src/assets/skill5.png';
// import Community from '../src/assets/service2.png';


//yeni data.js 
// import profile images
//edefewfewfe
// import ProfilePhoto1 from '../src/assets/img/profile1.png';
// import ProfilePhoto2 from '../src/assets/img/profile2.png';
// import ProfilePhoto3 from '../src/assets/img/profile3.png';

// import about icons
// import Skill1 from '../src/assets/dev.jpg';
// import Skill2 from '../src/assets/dev.jpg';
// import Skill3 from '../src/assets/dev.jpg';
// import Skill4 from '../src/assets/img/skills/skill4.png';
// import Skill5 from '../src/assets/img/skills/skill5.png';
// import Skill6 from '../src/assets/img/skills/skill6.png';

// import team images

// import TeamMember1 from '../src/assets/azamat.jpeg';
// import TeamMember2 from '../src/assets/kanybek.jpeg';
// // import TeamMember3 from '../src/assets/erhan.jpeg';
// // import TeamMember4 from '../src/assets/basak.jpeg';
// import TeamMember5 from '../src/assets/halid.jpeg';
// import TeamMember6 from '../src/assets/ozlem.jpeg';
// // import TeamMember6 from '../src/assets/dev.jpg';
// // import TeamMember7 from '../src/assets/rahmi.png';
// import TeamMember8 from '../src/assets/behruz.jpeg';
// import TeamMember9 from '../src/assets/gulhanim.png';
// import TeamMember10 from '../src/assets/ugur.jpeg';



// import social icons
// import FacebookIcon from '../src/assets/img/social/facebook.png';
// import TwitterIcon from '../src/assets/img/social/twitter.png';
// import InstagramIcon from '../src/assets/img/social/instagram.png';
// import YoutubeIcon from '../src/assets/img/social/youtube.png';
// import PinterestIcon from '../src/assets/img/social/pinterest.png';
// navigation data
export const navigation = [
    {
      name: 'HOME',
      href: 'home',
    },
    // {
    //   name: 'TEAM',
    //   href: 'team',
    // },
    // {
    //   name: 'PROGRAMS',
    //   href: 'programs',
    // },
    // {
    //     name: 'SERVICES',
    //     href: 'services',
    //   },
      {
        name: 'SERVICES',
        href: 'prices',
      },
      {
        name: 'WHY US',
        href: 'comparison',
      },
    {
      name: 'CONTACT US',
      href: 'contact',
    },
  ];

  // social data
// export const social = [
//     {
//       icon: FacebookIcon,
//     },
//     {
//       icon: TwitterIcon,
//     },
//     {
//       icon: InstagramIcon,
//     },
//     {
//       icon: YoutubeIcon,
//     },
//     {
//       icon: PinterestIcon,
//     },
//   ];

// export const testimonials = [
//   {
//     text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem rem ad consequuntur similique sequi illum dolorum omnis inventore labore odio!',
//     name: 'Jane Cooper',
//     image: ProfilePhoto1,
//     position: 'Web Developer',
//   },
//   {
//     text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem rem ad consequuntur similique sequi illum dolorum omnis inventore labore odio!',
//     name: 'Jane Cooper',
//     image: ProfilePhoto1,
//     position: 'Web Developer',
//   },
//   {
//     text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem rem ad consequuntur similique sequi illum dolorum omnis inventore labore odio!',
//     name: 'Jane Cooper',
//     image: ProfilePhoto1,
//     position: 'Web Developer',
//   },
//   {
//     text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem rem ad consequuntur similique sequi illum dolorum omnis inventore labore odio!',
//     name: 'Jane Cooper',
//     image: ProfilePhoto1,
//     position: 'Web Developer',
//   },
//   {
//     text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem rem ad consequuntur similique sequi illum dolorum omnis inventore labore odio!',
//     name: 'Theresa Webb',
//     image: ProfilePhoto2,
//     position: 'Web Designer',
//   },
//   {
//     text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem rem ad consequuntur similique sequi illum dolorum omnis inventore labore odio!',
//     name: 'Kathryn Murphy',
//     image: ProfilePhoto3,
//     position: 'UI/UX Designer',
//   },
// ];

export const comparisons = [
  {
    plan: "Prepare YOURSELF",
    description:"If you are very confident with interviews you can choose this package",
    features: [
      "Getting hired LATE",
      "STUCK on where to begin studying",
      "STRUGGLING with unknown interview topics and questions",
      "OVERHELMED by difficult learning areas",
      "WASTING too much time on learning",
      "LOSING MOTIVATION during the self-study",
      "Feeling HOPELESS during interview prep"
    ],
    isPopular: false,
  },
  {
    plan: "Prepare with US",
    description:"If you are confident with interviews you should select this package",
    features: [
      "Experience timely SUCCESS with our preparation",
      "Find CLEAR DIRECTION from our Experienced Tech Leads",
      "Focus on HIGH PRIORITY interview questions concept",
      "Ask and GET ANSWERS from your Tech Lead",
      "Maximize your learning efficiency for SWIFT PROGRESS",
      "Stay MOTIVATED with our coach and tech leads",
      "Discover hope and CONFIDENCE in your interview preparation journey"
    ],
    isPopular: true,
  },
]
// export const team = [
//   {
//     image: TeamMember6,
//     name: 'Ozlem',
//     position: 'Cybersecurity Engineer',
//     description: "StreamSets",
//     linkedin:"https://www.linkedin.com/in/ozlem-bulut/"
//   },
//   {
//     image: TeamMember1,
//     name: 'Azamat',
//     position: 'Frontend Developer',
//     description: "Relay Network",
//     linkedin: "https://www.linkedin.com/in/agaparkg/",
//   },
//   {
//     image: TeamMember2,
//     name: 'Kanybek',
//     position: 'Frontend  Developer',
//     description: "Deloitte",
//     linkedin: "https://www.linkedin.com/in/kanybek-duisheev-45773b82/",
//   },
//   // {
//   //   image: TeamMember4,
//   //   name: 'Brenna',
//   //   position: 'Salesforce Administrator',
//   //   description: "Grammy",
//   //   linkedin:"https://www.linkedin.com/in/bßrenna-dolapci-301a0b230/"
//   // },
//   {
//     image: TeamMember5,
//     name: 'Halid',
//     position: 'Data Science & M L',
//     description: "Purdue",
//     linkedin:"https://www.linkedin.com/in/halid-ziya-yerebakan-a7124226/"

//   },

//   // {
//   //   image: TeamMember7,
//   //   name: 'Rahmi',
//   //   position: 'QA Automation  Engineer',
//   //   description: "Nybble Group",
//   //   linkedin: "https://www.linkedin.com/in/rahmi-dagci-8a971779/",

//   // },
//   {
//     image: TeamMember8,
//     name: 'Behruz',
//     position: 'QA Automation Engineer',
//     description: "JPMC",
//     linkedin: "https://www.linkedin.com/in/behruz-jumaev-29aa061b2/",

//   },
//   {
//     image: TeamMember9,
//     name: 'Gulhanim',
//     position: 'QA Automation Engineer',
//     description: "Loadlink Technologies",
//     linkedin: "https://www.linkedin.com/in/hannah-anulur-qa-automation-engineer/",
//   },
// {
//     image: TeamMember10,
//     name: 'Ugur',
//     position: 'Java Backend Developer',
//     description: "Chase",
//     linkedin:"https://www.linkedin.com/in/utendurus/"
// },
// ];

export const prices = [
  // {
  //   plan: "BASIC",
  //   duration: "1 Month program",
  //   price: "$350",
    // description:"If you are very confident with interviews you can choose this package",
  //   features: [
  //     "Resume building",
  //     "Linkedin Optimization",
  //     "4 Mock Soft Skill Interview",
  //     "2 Mock Technical Interview",
  //     "1 Coaching sessions",
  //   ],
  //   isPopular: false,
  // },
  {
    plan: "RESUME BUILDING",
    // duration: "3 - Month Program",
    price: "$99",
    description:"Unlock the potential of your professional journey with our Resume Building service, where our experienced IT professionals craft resumes that showcase your skills and experience in the best light possible.",
    features: [
      "Resume is  building  by technical experts",
      "All resume builders are working currently in related fileds",
      "Our experts coaches have latest market knowledge",
      "A good resume is going to allow you to move forward to the next step in the process: interviewing"

      // "Linkedin optimization",
      // "12 Mock Soft Skill Interview sessions",
      // "8 Mock Technical Interview sessions",
      // "8 oaching sessions",
      // "Communication with Tech Lead for your all questions",
      // "Daily Job Applications Tracking"
    ],
    isPopular: false,
  },
  {
    plan: "LINKEDIN OPTIMIZATION",
    // duration: "3 Month Program",
    price: "$99",
    description:"Maximize your LinkedIn presence with our Optimization service. Our experts will transform your profile into a magnet for opportunities, ensuring you stand out and make meaningful connections in the competitive digital landscape.",
    features: [
      // "Resume Building",
      "According to a survey  73% of recruiters have hired a candidate through Linkedin",
      "Potentially leading to more job opportunities",
      "Your LinkedIn profile serves as an online resume and portfolio",
      "Having a well-maintained LinkedIn profile can increase your visibility"

      // "Weekly Soft Skill Interview Sessions",
      // "Wekly  Mock Technical Interview Sessions",
      // "Weekly Coaching sessions with Mentors",
      // "Daily chat with Experienced Technical Leads",
      // "Daily Job Application Tracking",
      // "Get Referral from Great IT Community"
    ],
    isPopular: false,
  },
];

// export const services = [
//   {
//     icon: Soft,
//     title: 'Soft Skills Development',
//     description:"Personal branding, and live behavioral workshops",
//     discover: "Be honest, go big",
//   },
//   {
//     icon: Brand,
//     title: 'Personal Branding',
//     description:"Resume building, LinkedIn profile optimization,make unique candidate's face",
//     discover: "interact with people",
//   },
//   {
//     icon: Mock,
//     title: 'Mock Technical Interviews',
//     description:"Live interview sessions in real-life simulated environments with interviewers from tech companies",
//     discover: "Mocking  before start in real",
//   },
//   {
//     icon: Feedback,
//     title: 'Personal Feedback',
//     description:"After Technical and Soft skills screening providing feedback",
//     discover: "Learning from faults",
//   },
//   {
//     icon: Skill5,
//     title: 'Real Work Experince Guidance',
//     description:"Our instructors work in tech company and they provide the latest tech stack knowledge",
//     discover: "Real experience is a power",
//   },
//   {
//     icon: Community,
//     title: 'Great Community',
//     description:"We have a great community that can help candidate to get hire quickly",
//     discover: "Good people, big chance",
//   },
// ];

const data = {
  en: {
    contact: {
      formFields: {
        title: "Contact",
        description: "Submit your application for pre-qualification",
        name: "Name",
        phone: "Phone",
        email: "Email",
        linkedin: "LinkedIn URL",
        message: "Message",
      },
      buttonText: "Submit Application",
    },
  },
  es: {
    contact: {
      formFields: {
        title: "Contact",
        description: "Submit your application for pre-qualification",
        name: "Name",
        phone: "Phone",
        email: "Email",
        linkedin: "LinkedIn URL",
        message: "Message",
      },
      buttonText: "Submit Application",
    },
  },
  tr: {
    contact: {
      formFields: {
        title: "Iletisim",
        description: "Formunuzu gonderiniz",
        name: "Adiniz",
        phone: "Telefon numaraniz",
        email: "Email adresiniz",
        linkedin: "LinkedIn adresiniz",
        message: "Mesajiniz",
      },
      buttonText: "Gonder",
    },
  },
};

export default data;
