import React, { useState } from 'react'
import axios from 'axios'
import { X } from 'react-feather'

const ChatUI = () => {
  const [message, setMessage] = useState('')
  const [isOpen, setIsOpen] = useState(true) // Set isOpen to true initially
  const [messages, setMessages] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const handleClose = () => {
    setIsOpen(false) // Set isOpen to false to hide the ChatUI
  }

  // Function to send a message
  const sendMessage = async () => {
    try {
      if (message.trim() === '') {
        setErrorMessage('Please enter your message')
        return
      }

      // Add the user's message to the message list
      setMessages([...messages, { sender: 'user', text: message }])

      // Send email to the admin
      await sendEmail(message)

      // Auto response to the user's message
      const autoResponse = "Thanks for your message! We'll get back to you soon."
      setMessages([...messages, { sender: 'bot', text: autoResponse }])

      // Clear the message input and error message after sending
      setMessage('')
      setErrorMessage('')
    } catch (error) {
      console.error('Error sending message:', error)
    }
  }

  // Function to handle message input change
  const handleInputChange = event => {
    setMessage(event.target.value)
    // Clear the error message when the user starts typing
    setErrorMessage('')
  }

  // Function to send email to the admin using Getform API
  const sendEmail = async message => {
    try {
      const formData = new FormData()
      formData.append('message', message)

      // Send message to Getform API
      await axios.post('https://getform.io/f/ec533899-7d5d-4d2b-a6c9-ae1d3a3101cd', formData, {
        headers: { Accept: 'application/json' }
      })
    } catch (error) {
      console.error('Error sending email:', error)
    }
  }

  // If isOpen is false, return null to prevent rendering the ChatUI
  if (!isOpen) return null

  return (
    <div className='bg-white shadow-md rounded-lg max-w-lg w-full'>
      <div className='p-4 border-b bg-blue-500 text-white rounded-t-lg flex justify-between items-center'>
        <p className='text-lg font-semibold'>Chat With Expert</p>
        <button onClick={handleClose} className='focus:outline-none'>
          <X className='w-6 h-6' />
        </button>
      </div>
      <div className='p-4 h-80 overflow-y-auto'>
        {/* Display messages */}
        <div className='message-list'>
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`message ${msg.sender === 'user' ? 'text-right bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-lg py-2 px-4 mb-2`}
            >
              {msg.text}
            </div>
          ))}
        </div>
      </div>
      <div className='p-4 border-t flex'>
        <input
          type='text'
          value={message}
          onChange={handleInputChange}
          className='w-full px-3 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500'
          placeholder='Type a message'
        />
        <button
          onClick={sendMessage}
          className='bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition duration-300'
        >
          Send
        </button>
      </div>
      {errorMessage && <p className='text-red-500 text-sm ml-4 mt-1'>🛑 {errorMessage}</p>}
    </div>
  )
}

export default ChatUI
