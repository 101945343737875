import React, { useState } from 'react'
import questions from '../questions'

const Questions = () => {
  const [filteredQuestions, setFilteredQuestions] = useState(questions)

  // Filter questions based on position and sort by date
  const filterQuestions = position => {
    if (position === 'All') {
      return questions
    } else {
      const filtered = questions.filter(question => question.position === position)
      // Sort questions by date, assuming date field is named 'date'
      filtered.sort((a, b) => new Date(b.date) - new Date(a.date))
      return filtered
    }
  }

  // Format date to display only day and month
  const formatDate = dateString => {
    const options = { month: 'long', day: 'numeric' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  // Handle position filter
  const handlePositionFilter = position => {
    const filtered = filterQuestions(position)
    setFilteredQuestions(filtered)
  }

  // Define colors for each column
  const positionColor = 'text-blue-500'
  const questionColor = 'text-green-500'
  const companyColor = 'text-yellow-500'
  const dateColor = 'text-pink-500'

  return (
    <section name='questions' className='w-full bg-[#00467d] text-gray-300 py-20'>
      <div className='max-w-[1000px] mx-auto p-4'>
        <div className='pb-8'>
          <h2 className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>
            Interview Questions
          </h2>
        </div>
        {/* Position filter buttons */}
        <div className='flex flex-wrap justify-center space-x-4 mb-4'>
          <button
            onClick={() => handlePositionFilter('All')}
            className='btn-filter bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2'
          >
            All Questions
          </button>
          <button
            onClick={() => handlePositionFilter('Cybersecurity')}
            className='btn-filter bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2'
          >
            Cybersecurity
          </button>
          <button
            onClick={() => handlePositionFilter('Frontend')}
            className='btn-filter bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2'
          >
            Frontend Developer
          </button>
          <button
            onClick={() => handlePositionFilter('Automation Test Engineer')}
            className='btn-filter bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mb-2 md:mb-0'
          >
            Automation Test Engineer
          </button>
        </div>

        {/* Questions table */}
        <div className='overflow-x-auto bg-white rounded-lg shadow-lg'>
          <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-800'>
              <tr>
                <th className='px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider'>
                  Position
                </th>
                <th className='px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider'>
                  Questions
                </th>
                <th className='px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider'>
                  Company
                </th>
                <th className='px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider'>
                  Date
                </th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200'>
              {filteredQuestions.map((question, index) => (
                <tr key={index} className='border-t border-gray-200'>
                  <td className={`px-6 py-4 whitespace-no-wrap ${positionColor}`}>
                    {question.position}
                  </td>
                  <td className={`px-6 py-4 whitespace-no-wrap ${questionColor}`}>
                    {question.question}
                  </td>
                  <td className={`px-6 py-4 whitespace-no-wrap ${companyColor}`}>
                    {question.company}
                  </td>
                  <td className={`px-6 py-4 whitespace-no-wrap ${dateColor}`}>
                    {formatDate(question.date)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}

export default Questions
