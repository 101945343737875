import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-scroll';
import { HiArrowNarrowRight } from 'react-icons/hi';
// import { useTranslation } from "react-i18next";
import resume from '../assets/resume1.png';

const Home = () => {
  const [nav, setNav] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleClick = useCallback(() => setNav((prevNav) => !prevNav), []);
  const handleMouseEnter = useCallback(() => setHovered(true), []);
  const handleMouseLeave = useCallback(() => setHovered(false), []);

  const currentYear = useMemo(() => new Date().getFullYear(), []);

  // const { t } = useTranslation();

  return (
    <div
      name='home'
      className='w-full h-screen bg-[#00467d] flex flex-col justify-center items-center'
    >
      <div className='max-w-[1000px] mx-auto px-9 flex-col justify-center h-full'>
        <div>
          <Link onClick={handleClick} to='questions' smooth={true} duration={500}>
            <button className='text-white group border-2 px-6 py-3 mt-20 flex items-center hover:bg-purple-600 hover:border-pink-600'>
              Check the latest Interview Questions
              <span className='group-hover:rotate-90 duration-300'>
                <HiArrowNarrowRight className='ml-3' />
              </span>
            </button>
          </Link>
        </div>
      </div>
      <div className='w-full flex justify-center items-center p-4 bg-[#00467d]'>
        {/* Container for the image and logo */}
        <div
          className='relative w-full max-w-[500px]'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* Image for free resume session events */}
          <Link onClick={handleClick} to='resume' smooth={true} duration={500}>
            <a href='/'>
              <img src={resume} alt='Resume Session' className='w-full h-auto' />
            </a>
          </Link>
          {/* Logo positioned at the bottom */}
        </div>
      </div>
      <footer className='fixed w-full h-[40px] flex justify-center items-center bg-black text-white px-4'>
        {`© Copyright ${currentYear} All Rights Reserved`}
      </footer>
    </div>
  );
}

export default Home;
