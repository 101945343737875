import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import i18n from "i18next";
import {  initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend'
import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap/dist/css/bootstrap.min.css"
import 'flag-icon-css/css/flag-icons.min.css';
import './index.css'

import App from "./App"
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    // resources: {  // resources can be removed since we installed http backend 118 package
    //   en: {
    //     translation: {
    //       "Welcome to React": "Welcome"
    //     }
    //   },
    //   es: {
    //     translation: {
    //       "Welcome to React": "Bienvenido"
    //     }
    //   },
    //   tr: {
    //     translation: {
    //       "Welcome to React": "Hosgeldiniz"
    //     }
    //   }
    // },
    // lng: document.querySelector("html").lang, // if you're using a language detector, do not define the lng option
    supportedLngs: ['en','es','tr','de'],
    fallbackLng: "en",
    detection: {
      order: [ 'cookie','htmlTag', 'localStorage', 'path','subdomain'],
      caches:['cookie']
    },
    backend: {
      loadPath: 'lang/locales/{{lng}}/translation.json',
    },
  });

  const languageMarkup = () =>{
<div>Loading</div>
  }
ReactDOM.render(
  <Suspense fallback={languageMarkup}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);