import axios from 'axios'
import { useState } from 'react'

const Resume = () => {
  const [formStatus, setFormStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState({
    name: '',
    email: '',
    linkedin: '',
    file: ''
  })

  const handleFileChange = () => e => {
    setQuery(prevState => ({
      ...prevState,
      file: e.target.files[0]
    }))
  }

  const handleChange = () => e => {
    const name = e.target.name
    const value = e.target.value
    setQuery(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (loading) return
    setLoading(true)
    const formData = new FormData()
    Object.entries(query).forEach(([key, value]) => {
      formData.append(key, value)
    })

    axios
      .post('https://getform.io/f/nadoledb', formData, {
        headers: { Accept: 'application/json' }
      })
      .then(function (response) {
        setFormStatus(true)
        setQuery({
          name: '',
          email: '',
          linkedin: '',
          file: ''
        })
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error)
        setLoading(false)
      })
  }

  return (
    <div
      name='resume'
      className='w-full h-screen bg-[#00467d] flex justify-center items-center p-4'
    >
      <form
        encType='multipart/form-data'
        onSubmit={handleSubmit}
        className='flex flex-col max-w-[600px] w-full'
      >
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 border-pink-600 text-gray-300'>
            Resume
          </p>
          <p className='font-bold text-[#ccd6f6] py-2 max-w-[800px]'>
            Share your resume with Resume Tech Experts
          </p>
        </div>
        <input
          className='bg-[#ccd6f6] p-2 mb-2'
          type='text'
          placeholder='Full name*'
          name='name'
          value={query.name}
          onChange={handleChange()}
          required
        />
        <input
          className='bg-[#ccd6f6] p-2 mb-2'
          type='email'
          placeholder='Email'
          name='email'
          value={query.email}
          onChange={handleChange()}
        />
        <input
          className='bg-[#ccd6f6] p-2 mb-2'
          type='url'
          placeholder='LinkedIn URL*'
          name='linkedin'
          required
          value={query.linkedin}
          onChange={handleChange()}
        />
        <div>
          <input
            type='file'
            name='file'
            onChange={handleFileChange()}
            className='bg-[#ccd6f6] p-2  w-full mb-2'
          />
        </div>
        <button
          className='bg-blue-500  text-white font-bold py-2 px-4 rounded   hover:bg-purple-600 hover:border-pink-600'
          type='submit'
        >
          Submit Your Resume
        </button>
        {formStatus && (
          <p className='text-green-500 text-center mt-4'>
            Thanks for submitting your resume! 🚀 We appreciate your interest and will review your
            information promptly. Expect to hear from us soon regarding your resume's feedback.
          </p>
        )}
      </form>
    </div>
  )
}

export default Resume
