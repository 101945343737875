import React,{useState} from "react";
import Header from "./components/Header";
import Home from "./components/Home";
import Price from "./components/Price"
import BackToTop from './components/BackToTop';
import Resume from "./components/Resume";
import Connect from "./components/Connect";
import Questions from "./components/Questions";
import ChatIcon from "./components/ChatIcon";
import ChatUI from "./components/ChatUI";

function App() {
	// const [isModalOpen, setIsModalOpen] = useState(true);
	const [isChatOpen, setIsChatOpen] = useState(false); // State to manage chat UI visibility


	// const closeModal = () => {
	// 	setIsModalOpen(false);
	//   };

	   // Function to toggle chat UI visibility
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

	return (
		<>
			<Header />
			{/* {isModalOpen && <ModalComponent closeModal={closeModal} />} */}
			<Home />
			<Resume />
			<Connect />
			<Price />
			<Questions />
			  {/* Conditionally render the chat icon based on chat UI visibility */}
			  {!isChatOpen && <ChatIcon toggleChat={toggleChat} />} 
			<BackToTop />
			{/* Conditionally render the chat UI based on isChatOpen state */}
			{isChatOpen && (
        <div className="fixed bottom-0 right-0 mb-4 mr-4">
          <ChatUI />
          {/* Add your chat UI code here */}
        </div>
      )}

		</>
	);
}

export default App;