import React, { useEffect, useState } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { FaChevronUp } from 'react-icons/fa'
import debounce from 'lodash/debounce'

const BackToTop = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const handleScroll = debounce(() => {
      setShow(window.scrollY > 100)
    }, 100)

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  return (
    show && (
      <button
        onClick={scrollToTop}
        className='fixed bottom-16 lg:bottom-8 right-8 w-12 h-12 bg-primary-hover rounded-full cursor-pointer text-white text-sm shadow-lg transition-transform transform hover:scale-110'
      >
        <span className='flex items-center justify-center h-full'>
          <FaChevronUp className='text-xl' />
        </span>
      </button>
    )
  )
}

export default BackToTop
