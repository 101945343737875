import React from 'react'
import cyber1 from '../assets/cyber1.png'
import qa from '../assets/selenium.png'
import JavaScript from '../assets/javascript.png'

const Connect = () => {
  return (
    <div name='connect' className='w-full h-screen  bg-[#00467d] text-gray-300 '>
      {/* {Container} */}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div>
          <p className='text-4xl font-bold inline border-b-4  border-pink-600 '>
            {' '}
            Get Hired Faster{' '}
          </p>
          {/* <p className="py-4"> These are the technologies I have used with</p> */}
        </div>
        <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
          <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
            <a href='/'>
              <img className='w-20 mx-auto' src={cyber1} alt='Cyber icon' />
            </a>
            <p className='my-4 text-yellow-500 text-2xl'>Cybersecurity Analyst</p>
          </div>
          <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
            <a href='/'>
              <img className='w-20 mx-auto' src={qa} alt='qa icon' />
            </a>
            <p className='my-4 text-yellow-500 text-2xl'>Test Automation Engineering</p>
          </div>
          <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
            <a href='/'>
              <img className='w-20 mx-auto' src={JavaScript} alt='javascript icon' />
            </a>
            <p className='my-4 text-yellow-500 text-2xl'>Frontend Development</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Connect
